import * as waxjs from '@waxio/waxjs/dist'
import WalletBase from './walletbase'

export default class WaxWallet extends WalletBase {
  wax = null
  username = null

  constructor(network) {
    super(network)
    const wax = new waxjs.WaxJS({ 
      rpcEndpoint: network.url, 
      userAccount: network.account,
      tryAutoLogin: true,
      pubKeys: network.keys
     })
    // wax.rpc = rpc
    this.wax = wax
  }

  async autologin() {
    const isAutoLoginAvailable = await this.wax.isAutoLoginAvailable()
    if (isAutoLoginAvailable) {
      this.username = this.wax.user.account
      return {
        name: this.wax.user.account,
        pubKeys: this.wax.pubKeys,
        authorization: {
          actor: this.wax.user.account, permission: 'active' 
        }
      }
    } else {
      return null
    }
  }

  async login() {
    this.wax.login().then(data=>console.log(data));
    const userAccount = await this.wax.login()
    console.log(userAccount);
    this.username = userAccount
    return {
      name: userAccount,
      pubKeys: this.wax.pubKeys,
      authorization: {
        actor: userAccount, permission: 'active'
      }
    }
  }


  user(){
    return this.username
  }


  transact(actions) {
    return this.wax.api.transact({ actions }, { blocksBehind: 3, expireSeconds: 1200 })
  }


  transact_id(_process){
    return _process.transaction_id;
  }
}
import AnchorLink from 'anchor-link'
// import PermissionLevel from 'anchor-link'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'
import WalletBase from './walletbase'

export default class AnchorWallet extends WalletBase {
  link = null
  chainId = null
  session = null
  username = null
  appname = null

  constructor(network) {
    super(network)
    this.appname = network.appname
    this.chainId = network.chainId
    this.createLink()
  }

  logout() {
    this.link = null
  }

  createLink() {
    if (this.link) return null

    const { network } = this

    this.link = new AnchorLink({
      transport: new AnchorLinkBrowserTransport(),
      chains: [
        {
          chainId: this.network.chainId,
          nodeUrl: `${network.url}`
        }
      ]
    })
  }

  async autologin() {
    if (!this.link) return null
    let sessions = await this.link.listSessions(this.appname);
    let act_sess = {auth:'',chainId:''}
    if ( sessions.length>0 ){
      act_sess = sessions[0] 
    }
    const session = await this.link.restoreSession(this.appname, act_sess.auth, act_sess.chainId)

    if (session) {
      this.session = session
      const { actor, permission } = session.auth
      this.username = actor.toString()
      return {
        name: actor.toString(),
        pubKeys: [],
        authorization: { actor: actor.toString(), permission: permission.toString() }
      }
    } else {
      return null
    }
  }

  async login() {
    if (!this.link) this.createLink()
    const identity = await this.link.login(this.appname)
    this.session = identity.session

    const { actor, permission } = this.session.auth
    this.username = actor.toString()
    return {
      name: actor.toString(),
      pubKeys: [],
      authorization: { actor: actor.toString(), permission: permission.toString() }
    }
  }

  user(){
    return ()=>this.username
  }

  transact(actions) {
    return this.session.transact({ actions });    
  }

  transact_id(_process){
    return _process.transaction.id;
  }
}
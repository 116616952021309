import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style.scss'
import 'sweetalert2/dist/sweetalert2.min.css';
// import "@/service/wax";
import Buffer from "vue-buffer";
// @ts-ignore
window.Buffer = Buffer;
import en from './locales/en'
import ru from './locales/ru'

import 'bootstrap/js/src/base-component'
import 'bootstrap/js/src/dropdown'

Vue.use(VueI18n)

Vue.use(VueCookies, { expire: '7d'})

Vue.use(VueSweetalert2);

const messages = {
  en,
  ru
}

// VueI18n instance
const i18n = new VueI18n({
  // Key - language to use the rule for, `'ru'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'ru': function(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  },
  locale: 'en',
  messages
})

Vue.config.productionTip = false

Vue.prototype.$bus = new Vue();

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // доступное для аренды место
    freeSpace:0,
    // залогиненый пользователь
    userAccount:false,
    // залогинен ли пользователь
    isLoggedIn:false,
    // баланс пользователя
    userBalance:0,
    // ысе пулы
    pools:[],
    activepool:'',
    // debug
    hostDebug:'',
    // hostDebug:'http://localhost:8000',
    endpoint:'https://wax.greymass.com',
    // wax variables
    tbl_row_path:'/v1/chain/get_table_rows',
    acc_row_path:'/v1/chain/get_account',
  },
  mutations: {
    setFreeSpace(state,val){
      state.freeSpace = val.toFixed(2);
    },
    setUserAccount(state,val){
      state.userAccount = val;
    },
    setIsLoggedIn(state,val){
      state.isLoggedIn = val;
    },
    setUserBalance(state,val){
      state.userBalance = val.toFixed(2);
    },
    setPools(state,val){
      state.pools = val;
    },
    setActivePool(state,val){
      state.activepool = val;
    },
    
  },
  actions: {
  },
  modules: {
  },
  getters:{
    freeSpace:(state)=>state.freeSpace,
    userAccount:(state)=>state.userAccount,
    isLoggedIn:(state)=>state.isLoggedIn,
    userBalance:(state)=>state.userBalance,
    pools:(state)=>state.pools,
    activepool:(state)=>state.activepool,
    hostDebug:(state)=>state.hostDebug,
    endpoint:(state)=>state.endpoint,
    tbl_row_path:(state)=>state.tbl_row_path,
    acc_row_path:(state)=>state.acc_row_path,
  }
})

<template>
 <div style="display: contents">
    <nav class="navbar sticky-top navbar-gray glass">
      <div class="d-flex flex-sm-row flex-column justify-content-between container">
        <langs :lang="'en'"/>

        <h2 class="navbar-text mb-0">WAX CPU Loan</h2>

        <div v-if="isLoggedIn" class="d-flex justify-content-end col-12 col-sm-3">
          <div class="fs-6">{{userAccount}}: {{userBalance}} WAX</div>
          <button class="exit-btn-content btn btn-secondary" @click="$bus.$emit('wax::logout')"></button>
        </div>
        <div v-else class="d-sm-flex justify-content-sm-end d-grid gap-2 col-12 col-sm-3 ">
          <button type="button" class="btn btn-primary py-3 py-sm-2" @click="$bus.$emit('wax::login')">{{ $t("buttons.login") }}</button>
        </div>
      </div>    
    </nav>
    
  </div>
</template>

<script>
import Langs from "@/components/Langs.vue";
import { mapGetters } from 'vuex'
export default {
  name: "LoginBlock",
  props: {
    title: String,
  },
  components: {
    Langs,    
  },
  computed:{
    ...mapGetters([
      'userAccount',
      'userBalance',
      'isLoggedIn',
    ])
  }
};
</script>

<style scoped lang="scss"></style>

export default {
    buttons: {
        save: "Сохранить",
        login:"Войти",
        logout:"Выйти",
    },
    messages:{
        available:"Доступно WAX для аренды",
        welcome:"Добро пожаловать",
        login:"Вход",
        day:'день | день | дня | дней',
        purchase:'Опции аренды',
        rent: 'АРЕНДА',
        foryou: 'ДЛЯ ТЕБЯ',
        availability: 'СЕЙЧАС ДОСТУПНО',
        rentwax: 'АРЕНДОВАТЬ WAX',
        contact: 'Контакты',
        stakedfor:'Аренда на ',
        amount: 'Сумма',
        buynow: 'Купить за ',
        success: 'Успешно',
        error: 'Ошибка',
        needlogin: 'Необходимо войти в аккаунт WAX',
        transactionsuccess:"Транзакция успешна!",
        viewtransact:"Посмотреть транзакцию",
        selectprovider:'Выберите способ авторизации',
    }
}
  
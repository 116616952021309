<template>
  <div class="d-none d-sm-flex justify-content-start col-12 col-sm-3">
    <button type="button" class="btn btn-secondary border-2 border-primary px-2 py-1" :class="{'active':this.$i18n.locale=='en'}" @click="changeLang('en')">En</button>
    <button type="button" class="btn btn-secondary border-2 border-primary px-2 py-1" :class="{'active':this.$i18n.locale=='ru'}" @click="changeLang('ru')">Ru</button>
  </div>
</template>

<script>
export default {
  name: "LangsBlock",
  props: {
    lang: String,
  },
  data(){
    return { langs: ['en', 'ru'] }
  },
  methods:{
    /**
     * Изменяем язык на выбранный
     */
    changeLang(n_lang){
      this.$i18n.locale=n_lang;
    }
  }
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="home">
    <Login title="WAX Login" />
    <div class="main-bg-line ">
      
      <div class="main-bg-content container w-100 d-flex flex-column align-content-center align-items-center justify-content-center">

        <div class="d-flex flex-column mb-5 ">
          <div class="main-header bigger text-uppercase">{{$t("messages.rent")}}</div>
          <div class="main-rotate-block main-header text-bg bg-primary px-4 py-3 my-2 text-uppercase">WAX CPU</div>
          <div class="main-header text-uppercase">{{$t("messages.foryou")}}</div>
        </div>

        <div class="d-flex flex-column flex-sm-row justify-content-between  w-100 mt-5  px-4 px-sm-1" >
          <div class="bg-white text-black d-flex flex-column px-3 py-2 main-click-btn mb-3 mb-sm-0">
            <span class="text-uppercase">{{$t("messages.availability")}}</span>
            <h2 class="text-uppercase mb-0 bold  fs-1">{{$store.getters.freeSpace}} WAX</h2>
          </div>

          <div class="text-black btn d-flex justify-content-between flex-row border-2 border-primary main-click-btn py-3 px-4 align-items-center" 
            @click="$bus.$emit('wax::scrollToContainer')">
            <h2 class="text-uppercase text-primary bold mb-0 fs-1">{{$t("messages.rentwax")}}</h2>
            <div class="text-primary arrow-icon"></div>
          </div>
        </div>

      </div>
      
    </div>
    
    <SignTransaction />

    <div class="footer mt-5 pt-5 d-flex flex-column flex-sm-row justify-content-between container mb-5 text-center text-sm-left">

      <div class="mb-3 mb-sm-0 d-flex flex-column">
        <div>{{$t("messages.contact")}} <a href="mailto:support@waxrent.com">support@waxrent.com</a></div>
      </div>
      <div>Copyright © 2022 | WAX CPU Loans </div>

    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Login from "@/components/Login.vue";
import SignTransaction from "@/components/SignTransaction.vue";

export default {
  name: "HomePage",
  data(){
    return {
      freeSpace: 0,
    }
  },
  components: {
    Login,
    SignTransaction,
  },
  mounted() {
    this.$nextTick( ()=>{
      this.$bus.$emit('wax::getactivepool');
      this.$bus.$emit('wax::getfreespace');
      this.$bus.$emit('wax::autologin');
    } )

    this.$bus.$on('wax::set::activepool',(data) => {
        this.setPools( [data] );
        this.setActivePool( data );  
    } );

    // запрашиваем постоянно остатки
    setInterval(()=> {
      this.$bus.$emit('wax::getfreespace' );
      this.$bus.$emit('wax::getuserbalance' );
    }, 15000);
  },
  methods:{
    ...mapMutations([
        'setActivePool',
        'setPools',
      ]),
  }
};
</script>

export default class WalletBase {
    network = null
    rpc = null
    username = null

    /**
     * Network.url - endpoint API
     * network.chainId - айди сети
     * @param {Object} network 
     */
    constructor(network, rpc=null) {
        this.network = network
        this.rpc = rpc
    }

    logout() {}
}
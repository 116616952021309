<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import Vue from "vue";
// import * as waxjs from "@waxio/waxjs/dist";
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import WaxWallet from "./wallets/wax"
import AnchorWallet from "./wallets/anchor"


// import { JsonRpc } from 'eosjs';
// import AnchorLink from "anchor-link";
// import AnchorLinkBrowserTransport from "anchor-link-browser-transport";

// import {Anchor} from './service/anchor';
export default{
    data(){
        return{
            // wax : null,
            provider: null,
            chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
            appname:'waxrent.com',
        }
    },
    watch: {
      activepool (newPool,oldPool) {
        // Our fancy notification (2).
        if ( newPool!=oldPool ){
          this.$nextTick( ()=>this.GetFreeSpace() ) ;
        }
      }
    },
    methods: {
        /**
         * Пробуем провести оплату
         * @param {*} c сколько берут в аренду
         * @param {*} coins плата за аренду
         */
         pay(data) {
            let day = data.day;
            let stakesum = data.stakesum;
            let tax = parseFloat(data.tax).toFixed(8);
            // должен вызываться push_transaction

            if ( !this.provider ){
              this.$swal({
                  title: this.$t("messages.error"),
                  icon: 'error',
                  text: this.$t("messages.needlogin"),
                })
              return false;
            }

            this.transfer( this.activepool, tax, day )
                .then( (result)=>{
                    let _tid = this.provider.transact_id(result);
                    // проверяем что перевод успешный
                    // и после этого уже проводим стейкинг
                    if (!result){
                      return;
                    }
                    this.$swal({
                      title: this.$t("messages.success"),
                      icon: 'success',
                      html: this.$t("messages.transactionsuccess")+' <br> <a href="https://wax.bloks.io/transaction/' + _tid + '?tab=traces">'+this.$t("messages.viewtransact")+'</a>',
                    })
                    this.GetFreeSpace();
                    this.saveTransaction( this.activepool, this.provider.username, tax, stakesum, day, _tid );
                } )
               .catch ( (e) => 
                  {console.log(e);
                  this.$swal({
                          title:  this.$t("messages.error"),
                          icon: 'error',
                          text: e,
                  });}
               )
            
            // 'eosio', 'delegatebw',
        },

        /**
         * Refund button
         */
        refund(){
          let actions=[{
            account: this.provider.username,
            name: 'waxrefund',
            authorization: [{
                actor: this.provider.username,
                permission: 'active',
            }],
            data: {},
          }];

          let provider_ = this.getProvider()
          if ( provider_!=false && provider_!==null ){
            // provider_.contract(this.provider.user).then( )
            provider_.transact( actions )
                .then( (result)=>{
                    // проверяем что перевод успешный
                    // и после этого уже проводим стейкинг
                    if (!result){
                      return;
                    }
                    this.$swal({
                      title: this.$t("messages.success"),
                      icon: 'success',
                      html: this.$t("messages.transactionsuccess")+' <br> <a href="https://wax.bloks.io/transaction/' + result.transaction_id + '?tab=traces">'+this.$t("messages.viewtransact")+'</a>',
                    })
                } ).catch( (e)=> console.log(e) )
          }
          return false;
        },

        remove(id){
          // console.log(id,Number.isFinite(id) );
          let actions=[{
            account: this.provider.username,
            name: 'removerow',
            authorization: [{
                actor: this.provider.username,
                permission: 'active',
            }],
            data: { 'id_': parseInt(id) },
          }];

          let provider_ = this.getProvider()
          if ( provider_!=false&& provider_!==null ){
            // provider_.contract(this.provider.user).then( )
            provider_.transact( actions )
                .then( (result)=>{
                    // проверяем что перевод успешный
                    // и после этого уже проводим стейкинг
                    if (!result){
                      return;
                    }
                    this.$swal({
                      title: this.$t("messages.success"),
                      icon: 'success',
                      html: this.$t("messages.transactionsuccess")+' <br> <a href="https://wax.bloks.io/transaction/' + result.transaction_id + '?tab=traces">'+this.$t("messages.viewtransact")+'</a>',
                    })
                    // обновляем информацию
                    this.$bus.$emit('wax::admin::loadtable');
                } ).catch( (e)=> console.log(e) )
          }
          return false;
        },
        
        /**
         *
         */
        async saveTransaction( pool_, username_, tax_, stakesum_, day_, transactionid_ ){
          let data = JSON.stringify({
            pool: pool_,
            username: username_,
            tax: tax_,
            stakesum: stakesum_,
            period: day_,
            transactionid: transactionid_
          });
          await fetch( this.hostDebug+'/api/data/insert', {
            headers: {
                "Content-Type": "application/json"
            },
            body: data,
            method: "POST"
          });
          // const body = await response.json();
        },

        /**
         * Получаем доступное место
         */
        GetFreeSpace() {
          let _vue = this;
          // _vue.setFreeSpace(0);
          if ( _vue.pools && _vue.pools.length >0 ){
            var data = JSON.stringify({
                json: true,
                code: "eosio.token",
                scope: _vue.activepool,
                table: "accounts",
                lower_bound: "WAX",
                upper_bound: "WAX",
                limit: 1
            });
            fetch( this.endpoint + this.tbl_row_path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: data,
                method: "POST"
            }).then( (response)=>{
              return response.json();
            } ).then( (body)=>{
              if (body.rows && Array.isArray(body.rows) && body.rows.length == 1) {
                let this_wax = parseFloat(body.rows[0].balance);
                _vue.setFreeSpace(this_wax);
              } else {
                _vue.setFreeSpace(0);
              }

            } );
          
          }
        },


        /**
         * Запрашиваем баланс пользователя
         */
        async  GetUserBalance() {
          let _vue = this;
          _vue.setUserBalance(0);
          if ( this.$store.getters.userAccount ){
            var data = JSON.stringify({
              json: true,
              code: "eosio.token",
              scope: this.$store.getters.userAccount,
              table: "accounts",
              lower_bound: "WAX",
              upper_bound: "WAX",
              limit: 1
            });
            // const response = await fetch( 'https://wax.greymass.com' + this.tbl_row_path, {
            const response = await fetch( this.endpoint + this.tbl_row_path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: data,
                method: "POST"
            });
            const body = await response.json();
            if (body.rows && Array.isArray(body.rows) && body.rows.length == 1) {
              _vue.setUserBalance(parseFloat(body.rows[0].balance) );
            }
          }
        },

        /**
         * Загружаем пулы
         */
        async getpools(){
          try{
            const response = await fetch( this.hostDebug+'/api/pools', {
                  headers: {
                      "Content-Type": "application/json"
                  },
                  method: "GET"
            });
            const body = await response.json();
            if (body && Array.isArray(body) && body.length >0) {
              this.setPools( body );
            }
          }
          catch(e){
            console.log('Error while loading ', e)
          }
        },

        /**
         * Загружаем пулы
         */
        async getactivepool(){
          try{
            const response = await fetch( this.hostDebug+'/api/activepool', {
                  headers: {
                      "Content-Type": "application/json"
                  },
                  method: "GET"
            });
            const body = await response.json();
            if (body && body!=''){
              this.$bus.$emit('wax::set::activepool',body);
            }
          }
          catch(e){
            console.log('Error while loading ', e)
          }
        },
       
        /**
         * Show login select window
         */
        showLogin(){
          let _vue = this;
          this.$swal.fire({
            title: this.$t("messages.selectprovider"),
            showOkButton: false,
            showConfirmButton: false,
            showCloseButton: false,
            showDenyButton: false,
            showCancelButton: false,
            focusConfirm: false,
            html: '<div class="d-flex justify-content-center flex-column flex-md-row p-2"> <div class="p-2 btn d-block"> <div class="wax-logo "></div> </div> <div class="p-2 btn d-block"><div class="anchor-logo "></div></div> </div>',
            didOpen: () => {
              const w = this.$swal.getHtmlContainer().querySelector('.wax-logo')
              const a = this.$swal.getHtmlContainer().querySelector('.anchor-logo')
              w.addEventListener('click', () => {
                let provider_ = 'wax';
                this.$cookies.set('blockchainprovider',provider_)
                _vue.loginProvider(provider_)
                this.$swal.close();
              })

              a.addEventListener('click', () => {
                let provider_ = 'anchor';
                this.$cookies.set('blockchainprovider',provider_)
                _vue.loginProvider(provider_)
                this.$swal.close();
              })
            },
            willClose: () => {
              
            }
          })
        },

        /**
         * Login method
         */
        login() {
            try {
              let provider_ = this.$cookies.get('blockchainprovider');
              if (this.provider==null && provider_){        
                // make login wallet
                this.loginProvider(provider_)
              }
            } catch (e) {
                this.$cookies.set('waxlogin','')
                this.$cookies.set('waxkeys',[])
                this.setUserAccount(false);
                this.setIsLoggedIn(false);
                console.error(e.message);
                // document.getElementById('loginresponse').append(e.message);
            }
        },

        /**
         * Try automatic login
         */
        autoLogin(){
          try {
            let provider_ = this.$cookies.get('blockchainprovider');
            if (this.provider==null && provider_){        
              // make login wallet
              this.setProvider(provider_)
            }
            if (this.provider!=null){
              this.provider.autologin().then( data=>{
                this.$cookies.set('waxlogin',data.name)
                this.$cookies.set('blockchainprovider',provider_)
                this.$cookies.set('waxkeys',data.pubKeys)
                this.setUserAccount(data.name);
                this.setIsLoggedIn(true);
                this.GetUserBalance();
              } ).catch(()=>{
                // console.log(e);
                this.logout();
              })
            }
          }catch (e) {
            console.log(e);
          }
        },

        /**
         * Logout method
         */
        logout(){
          this.$cookies.set('blockchainprovider','')
          this.$cookies.set('waxlogin','')
          this.$cookies.set('waxkeys',[])
          this.setUserAccount(false);
          this.setIsLoggedIn(false);

          this.provider = null
        },

        /**
         * Перевод средств на кошелек
         * @param {String} to Куда переводятся средства
         * @param {Int} amount Количество ваксов
         * @returns 
         */
        async transfer(to, amount, day) {
          let provider_ = this.getProvider()
          if ( provider_!=false ){
            return provider_.transact([{
                        account: 'eosio.token',
                        name: 'transfer',
                        authorization: [{
                            actor: this.provider.username,
                            permission: 'active',
                        }],
                        data: {
                            from: this.provider.username,
                            to: to,
                            quantity: String(amount) + ' WAX',
                            memo: this.provider.username+":"+day, // timeMultiplier (days)
                        },
                    }]);
          }
          return false;
        },

        /**
         * Emit EOSIO contract transaction
         */
        getProvider(){
          if (!this.provider) {
                this.$swal({
                  title: this.$t("messages.error"),
                  icon: 'error',
                  text: this.$t("messages.needlogin"),
                })
                return false;
            }
            try {
              if ( this.provider ){
                
                return this.provider;
              }                
            } catch (e) {
              this.$swal({
                      title:  this.$t("messages.error"),
                      icon: 'error',
                      text: e.message,
              })
            }
            return false;
        },

        /**
         * Unstake trnasaction
         */
        async unstake(id){
          let actions=[{
            account: this.provider.username,
            name: 'manualunstake',
            authorization: [{
                actor: this.provider.username,
                permission: 'active',
            }],
            data: { 'id_':id },
          }];

          let provider_ = this.getProvider()
          if ( provider_!=false&& provider_!==null ){
            // provider_.contract(this.provider.user).then( )
            provider_.transact( actions )
                .then( (result)=>{
                    // проверяем что перевод успешный
                    // и после этого уже проводим стейкинг
                    if (!result){
                      return;
                    }
                    this.$swal({
                      title: this.$t("messages.success"),
                      icon: 'success',
                      html: this.$t("messages.transactionsuccess")+' <br> <a href="https://wax.bloks.io/transaction/' + result.transaction_id + '?tab=traces">'+this.$t("messages.viewtransact")+'</a>',
                    })
                    // обновляем информацию
                    this.$bus.$emit('wax::admin::loadtable');
                } ).catch( (e)=> console.log(e) )
          }
          return false;
        },
        
        /**
         * Login by specified provider
         */
        loginProvider(provider_){
          this.setProvider(provider_);
          if ( this.provider!==null ){
            this.provider.login().then( data=>{              
              this.$cookies.set('waxlogin',data.name)
              this.$cookies.set('blockchainprovider',provider_)
              this.$cookies.set('waxkeys',data.pubKeys)
              this.setUserAccount(data.name);
              this.setIsLoggedIn(true);
              this.GetUserBalance();
            } ).catch(()=>{
              // console.log(e);
              this.logout();
            })
          }
        },

        setProvider(provider_){
          let keys = this.$cookies.get('waxkeys');
          
          if ( keys && keys!==undefined && keys!=='undefined' ){
            keys = keys.split(',')
          }else{
            keys = []
          }
          this.provider = null;
          switch (provider_) {
            case 'wax':
              this.provider = new WaxWallet( { url: this.endpoint, account: this.$cookies.get('waxlogin')??'', keys: keys  } )
              break;
            case 'anchor':
              this.provider = new AnchorWallet( { url: this.endpoint, chainId: this.chainId, appname: this.appname } )
              break;
          
            default:
              break;
          }
        },

      ...mapMutations([
        'setUserAccount',
        'setIsLoggedIn',
        'setFreeSpace',
        'setUserBalance',
        'setActivePool',
        'setPools',
      ]),
    },
    computed:{
      ...mapGetters([
        'activepool',
        'pools',
        'hostDebug',
        'userAccount',
        'endpoint',
        'tbl_row_path',
      ])
    },
    mounted(){

      this.$bus.$on('wax::getfreespace', ()=>this.GetFreeSpace() );
      this.$bus.$on('wax::getuserbalance', ()=>this.GetUserBalance() );
      this.$bus.$on('wax::getpools', ()=>this.getpools() );
      this.$bus.$on('wax::getactivepool', ()=>this.getactivepool() );
      this.$bus.$on('wax::unstake', (id)=>this.unstake(id) );
      this.$bus.$on('wax::remove', (id)=>this.remove(id) );
      this.$bus.$on('wax::autologin', ()=>this.autoLogin() );
      // this.$bus.$on('wax::login', ()=>this.login() );
      this.$bus.$on('wax::login', ()=>this.showLogin() );
      this.$bus.$on('wax::logout', ()=>this.logout() );
      this.$bus.$on('wax::pay', (data)=>this.pay(data) );
      this.$bus.$on('wax::refund', ()=>this.refund() );
      
      
    },
    created(){
      this.setPools( ["waxcpuloan1p"] );
      this.setActivePool( "waxcpuloan1p" );

      let provider_ = this.$cookies.get('blockchainprovider');

      if (this.provider==null && provider_){        
        // make login wallet
        this.setProvider(provider_)
      }
    }
}
</script>
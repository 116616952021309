export default {
    buttons: {
        save: "Save",
        logout:"Logout",
        login:"Login",
    },
    messages:{
        available:"Total WAX available",
        welcome:"Welcome",
        login:"Login",
        day:'day | day | days',
        purchase:'Purchasing Options',
        rent: 'RENT',
        foryou: 'FOR YOU',
        availability: 'CURRENT AVAILABILITY',
        rentwax: 'RENT WAX',
        contact: 'Contact',
        stakedfor:'Staked for ',
        amount: 'Amount',
        buynow: 'Buy now',
        success: 'Success',
        error: 'Error',
        needlogin: 'You need to login first',
        transactionsuccess:"Transaction success!",
        viewtransact:"View transaction",
        selectprovider:'Select login provider',
    }
}
  
<template>
  <div class="container" ref="waxcontainer">
    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-3">
      <h2 class="bold mb-0">{{$t("messages.purchase")}}:</h2>
      <div class="d-flex flex-row ">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle text-primary p-3 hover-primary" type="button" id="PoolBottom" data-bs-toggle="dropdown" aria-expanded="false">
            WAX pool
          </button>
          <ul class="dropdown-menu border-2 border-primary bg-secondary p-0 m-0" aria-labelledby="PoolBottom">
            <li v-for="(p,index) in $store.getters.pools" :key="index" :class="{active:p==$store.getters.activepool}" ><a class="dropdown-item text-center text-uppercase" @click="$store.commit('setActivePool',p)">{{p}}</a></li>
          </ul>
        </div>
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle text-primary p-3 hover-primary" type="button" id="DayBottom" data-bs-toggle="dropdown" aria-expanded="false">
            {{$t("messages.stakedfor")}} {{day}} {{ $tc("messages.day", day) }}
          </button>
          <ul class="dropdown-menu border-2 border-primary bg-secondary p-0 m-0" aria-labelledby="DayBottom">
            <li v-for="(h,index) in days"
                :key="'day-'+index"
                @click="day=h" 
                :class="{active:day==h}">
              <a class="dropdown-item text-center text-uppercase" >{{h}} {{ $tc("messages.day", h) }}</a>
            </li>
            <li class="text-center text-uppercase p-2"><input class="underline" type="number" v-model="day" /> </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-sm-3">
      <div class="col pe-sm-1 pb-3"
        v-for="(c,index) in costs"
        :key="index">
        <div class="border-2 border-primary bg-secondary p-4 d-flex flex-column">
          <div class="mb-1 fs-1 fs-sm-2">{{$t("messages.amount")}} / {{day}} {{ $tc("messages.day", day) }}</div>
          <h2 class="bold mb-3 fs-1">{{c}} WAX</h2>
          <div class="d-grid d-sm-flex">
            <button class="btn btn-primary bold py-4 py-sm-2 " 
              @click="$bus.$emit('wax::pay',{'day':day,'stakesum':c, 'tax':cost(c)})" >
              {{$t("messages.buynow")}} {{cost(c)}} WAX 
            </button>
          </div>          
        </div>
      </div>
      <div class="col pe-1 pb-3">
        <div class="border-2 border-primary bg-secondary p-4 d-flex flex-column" >
          <div class="mb-1 fs-1 fs-sm-2">{{$t("messages.amount")}} / {{day}} {{ $tc("messages.day", day) }}</div>
          <h2 class="bold mb-3 d-flex flex-row align-items-center fs-1"><input type="text" class="underline me-2"  v-model="cost_cur"> WAX</h2>
          <div class="d-grid d-sm-flex">
            <button class="btn btn-primary bold py-4 py-sm-2 " 
              @click="$bus.$emit('wax::pay',{'day':day,'stakesum':cost_cur, 'tax':cost(cost_cur)})">
              {{$t("messages.buynow")}} {{cost(cost_cur)}} WAX 
            </button>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignTransaction",
  data() {
    return {
      day: 1,
      days:[ 1, 2, 3 ],
      cost_cur:0,
      costs:[ 10, 50, 100, 200, 500 ],
    };
  },
  methods:{
    cost(coins=1){
      // исходя из срока аренды и суммы рассчитваем комисиию
      // 1 день - 1% в сутки
      // 2 дня - 0.8% в сутки
      // 3 и более - 0.7% в сутки
      let tax = 0.0045;
      // if (this.day==2){
      //   tax=0.008
      // }
      // if (this.day==1){
      //   tax=0.01
      // }
      return (coins*tax*(this.day)).toFixed(3)
    },
    scrollToElement() {
      const el = this.$refs.waxcontainer;
      // console.log(this.$refs);
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  mounted() {
    this.$bus.$on('wax::scrollToContainer',()=>this.scrollToElement() );
  }
};
</script>

<style scoped lang="scss"></style>
